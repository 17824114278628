import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Embeds from "../../components/embeds";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Description = styled.div`
  width: calc(100% - 2rem);
  height: 100%;
  max-width: 800px;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NiceLink = styled(Link)`
  color: inherit;
  font-weight: 600;
  font-family: Kanit;
`;

const ClientBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ClientBox = styled.div`
  display: flex;
  flex-direction: column;

  margin: 1rem;

  overflow: hidden;
  border-radius: 0.4rem;

  border: 2px solid black;

  p {
    margin: 0;
    padding: 0;
    color: white;
  }
`;

const ClientLogo = styled.div`
  padding: 1rem;
  height: 10rem;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Underlay = styled.div`
  background: black;
  padding: 1rem;
`;

const SilentLink = styled(Link)`
  text-decoration: none;
`;

const ClientsPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  return (
    <Layout navFill>
      <SEO title="Clients" />
      <Embeds />
      <Container>
        <Description>
          <h1>Our Clients</h1>
          <p>Browse a selection of Verdn's clients and read their stories.</p>
          <ClientBoxes>
            {allMarkdownRemark.edges.map(
              ({
                node: {
                  frontmatter: { title, logoImage },
                  fields: { slug },
                },
              }) => (
                <SilentLink to={slug}>
                  <ClientBox>
                    <ClientLogo>
                      <Img
                        fluid={logoImage.childImageSharp.fluid}
                        style={{ width: "100%", height: "100%" }}
                        imgStyle={{ objectFit: "contain" }}
                      />
                    </ClientLogo>
                    <Underlay>
                      <p>{title}</p>
                    </Underlay>
                  </ClientBox>
                </SilentLink>
              )
            )}
          </ClientBoxes>
        </Description>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___title }) {
      edges {
        node {
          frontmatter {
            title
            logoImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default ClientsPage;
